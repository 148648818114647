

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  FilterInput,
  FilterConditions
} from '@/shared/services/filter/filter.service';
DateType;

import { DateType } from '@/pages/Accounting/models/filters/date-filter.model';

import DateFilter from '@/shared/components/filters/DateFilter.vue';

import moment from 'moment-timezone';
import { BS_DATEPICKER_FORMAT } from '@/utils/date.util';

@Component({
  components: { DateFilter }
})
export default class DailyInOutFilters extends Vue {
  @Prop() type: 'inventory' | 'dayInOut';

  @Watch('$route', { immediate: true })
  onRouterChange(val, oldVal) {
    // TODO
    // Init module
  }

  selectionTypes = {
    list: [
      { value: 'containerNumber', text: 'Container Number' },
      { value: 'chassisNumber', text: 'Chassis Number' },
      { value: 'bareChassis', text: 'Bare Chassis' },
      { value: 'truckNumber', text: 'Truck Number' },
      { value: 'plateNumber', text: 'Plate Number' }
    ],
    autocompleteList: []
  };

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  excelData: any;

  constructor() {
    super();
  }

  search() {
    // AccountingModule.setFilters(
    //   TransformFiltersToJson([this.toFilter, this.fromFilter])
    // );
    // AccountingModule.search(AccountingTypes[this.$route.params.type]);
  }
}
