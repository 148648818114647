















































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { TableCategory, TableHeaders, CellContentType } from './models';

import Loading from '@/shared/components/Loading.vue';
import {
  BS_DATEPICKER_FORMAT,
  BS_DATEPICKER_WITH_TIME,
  convertAPIToFormat,
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME
} from '@/utils/date.util';
import DriverInputWrapper from '@/shared/components/form/DriverInputWrapper.vue';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';
import { deepClone } from '@/utils/utils';
import moment from 'moment-timezone';

Component.registerHooks(['validations']);

@Component({
  components: { Loading, DriverInputWrapper }
})
export default class TmsTable extends Vue {
  @Watch('extendedData', { immediate: true, deep: true })
  updateDate() {
    this.columnHeaders.forEach(header => {
      if (header.content && header.content.type === CellContentType.Date) {
        this.extendedData.forEach(item => {
          item[header.key] = convertAPIToFormat(
            item[header.key],
            BS_DATEPICKER_FORMAT
          );
        });
      }
    });
  }
  @Watch('columnHeaders', { immediate: true, deep: true })
  setColumns() {
    this.columns = this.columnHeaders;
    this.columnHeaders.forEach(i => (i.sortable = true));
  }

  @Prop() loading: boolean;
  @Prop() numberedColumn: number;
  @Prop() autoComplete;
  @Prop({ default: false }) hidePagination: boolean;
  @Prop({ default: false }) hideRowRanges: boolean;
  @Prop({ default: 150 }) perPage: number;
  @Prop({ default: null }) tableCategories: TableCategory[];
  @Prop({ default: null }) tbodyTrClass: any;
  @Prop({ default: null }) sortBy: string;
  @Prop({ default: false }) sortDescDirection: boolean;
  @Prop({ default: () => [] }) columnHeaders: TableHeaders[];
  @Prop({ default: () => [] }) tableData: any[];
  @Prop({ default: false }) disabled: boolean;

  currentPage = 1;
  maxPage = 1;
  CellContentType = CellContentType;
  DropLiveType = DropLiveType;
  columns = [];
  perPageRanges = [5, 25, 50, 100, 150, 300];
  range = null;
  initialExtendedData = [];

  constructor() {
    super();
    this.range = this.perPage;
  }

  // mounted() {
  //   // Make all items sortable
  //   this.columnHeaders
  //     .filter(
  //       i =>
  //         i.content.type === CellContentType.Text ||
  //         i.content.type === CellContentType.Number
  //     )
  //     .forEach(i => (i.sortable = true));
  //   if (this.numberedColumn) {
  //     this.columnHeaders.splice(this.numberedColumn - 1, 0, {
  //       key: 'number',
  //       label: `NO`,
  //       sortable: true
  //     });
  //   }
  // }

  get extendedData() {
    const copiedData = JSON.parse(JSON.stringify(this.tableData));
    this.initialExtendedData = deepClone(copiedData);
    return copiedData.map((item, i) => {
      item._rowId = Math.random().toString(36).substr(2, 9);
      item.number = i + 1;
      return item;
    });
  }

  get rowsAmount() {
    return this.tableData.length;
  }

  get visibleFields() {
    return this.columns.filter(item => {
      if ('collapsed' in item) {
        return !item.collapsed;
      } else {
        return item;
      }
    });
  }

  fetchRequest() {
    return this.autoComplete;
  }

  getCellClass({ getClass }, data) {
    return getClass ? getClass(data) : '';
  }

  hideFields(key) {
    this.columns = this.columns.map(item => {
      if (item.rendererKey == key) {
        item.collapsed = !item.collapsed;
      }
      return item;
    });
  }

  formInvalid() {}

  validate(str, skipValidation?: boolean, validator?, row?) {
    if (skipValidation) {
      return null;
    }

    if (
      validator &&
      validator.name === 'requiredIf' &&
      row[validator.value] &&
      !str
    ) {
      return false;
    }

    if (
      validator &&
      validator.name === 'requiredUnless' &&
      !row[validator.value] &&
      !str
    ) {
      return true;
    }

    if (
      validator &&
      validator.name === 'regular' &&
      str &&
      !str.match(new RegExp(validator.value))
    ) {
      return false;
    }

    if (!str && validator) {
      return null;
    }

    if (
      !str ||
      (typeof str === 'string' && str.trim() === '') ||
      str === 'Invalid date'
    ) {
      return false;
    } else {
      return null;
    }
  }

  clickEvent(key, data) {
    this.$emit('action', { key, data });
  }

  rowClick(item, index, event) {
    this.clickEvent('row', item);
  }

  onRowContextMenu(item, index, event: Event) {
    this.$emit('rowContextMenu', item, index, event);
  }

  updateSchedule(item, { jobPayments, schedules }) {
    item.jobPayments = jobPayments;
    item.schedules = schedules;
  }

  onInputChange(field: string, data) {
    this.$emit('inputChange', { field, data });
  }

  onChange(item, field) {
    if (typeof field?.onChange !== 'function') {
      return;
    }

    item = field.onChange(item);
  }

  isDriverAssignAllowed(row?: any, locationType?: any): boolean {
    let isNotAllowed = false;

    if (row && row.schedules.length) {
      row.schedules.forEach((schedule, index) => {
        if (schedule.actualInTime && schedule.actualIn) {
          const data = moment(
            `${moment(schedule.actualIn, DATE_API_FORMAT).format(
              DATE_API_FORMAT_WITHOUT_TIME
            )} ${schedule.actualInTime}`,
            BS_DATEPICKER_WITH_TIME
          );
          if (data) {
            isNotAllowed = true;
          }
        }
        if (
          [LocationType.DELIVERY, LocationType.RETURN].includes(locationType)
        ) {
          isNotAllowed = true;
        } else if (
          [LocationType.PICKUP, 'STOP'].includes(locationType) &&
          Object.keys(row.schedules).length - 1 === index &&
          row.schedules[row.schedules?.length - 1].uuid === schedule.uuid
        ) {
          isNotAllowed = true;
        }
      });
    }
    return isNotAllowed;
  }
}
